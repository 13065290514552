import * as yup from "yup";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Пароль должен быть не менее 8 символов")
    .test(
      "not-only-numbers",
      "Пароль не должен состоять только из цифр",
      (value) => !/^\d+$/.test(value)
    )

    .required("Пароль обязателен"),
});

export const validateForm = (formData) => {
  return validationSchema.validateSync(formData, { abortEarly: false });
};
