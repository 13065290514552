import { useRoutes, Routes, Route } from "react-router-dom";
import { backOfficeRoutes, routes } from "./routes";
import withRedux from "./hoc/withRedux";

import AppLoader from "./hoc/appLoader";
import { FeedbackForm, Footer, Header, Modal } from "./components";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { ModalProvider, useModal } from "./hooks/useModal";
import { MediaModel } from "./components/ui";
import { LoginProvider } from "./hooks/useLogin";
import { useResize } from "./hooks/useResize";

import "./styles/variables.scss";
import { LoginPage } from "@pages/LoginPage";
import { useRef, useState } from "react";
import { useSelector } from "react-redux";

function App() {
  const appRoutes = useRoutes(routes);
  const [modalOpen, setmodalOpen] = useState(false);
  const ref = useRef();
  const isAuth = useSelector((state) => state.auth.auth);
  const { isScreenMd } = useResize();
  return (
    <div>
      <AppLoader>
        <ToastContainer position={isScreenMd ? "top-right" : "bottom-center"} />

        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="reset/password" element={<LoginPage />} />
          <Route path="restore" element={<LoginPage />} />
          <Route
            element={
              <div className="relative min-h-screen flex flex-col justify-between">
                <LoginProvider>
                  <Header />
                  <ModalProvider>
                    <main className="h-full flex-grow mb-20">{appRoutes}</main>
                    {isAuth && (
                      <button
                        style={{ borderRadius: "75px 75px 0px 75px" }}
                        className="fixed z-40 p-5 top-2/3 h-24 right-5 bg-text-primary-green text-white"
                        onClick={() => setmodalOpen(true)}
                      >
                        Обратная <br /> связь
                      </button>
                    )}

                    <MediaModel />
                  </ModalProvider>
                  <Footer />
                </LoginProvider>
              </div>
            }
            path="/*"
          />
        </Routes>

        {modalOpen && (
          <Modal ref={ref} onClose={() => setmodalOpen(false)}>
            <FeedbackForm onClose={() => setmodalOpen(false)} />
          </Modal>
        )}
      </AppLoader>
    </div>
  );
}

const AppWithStoreAndRoutes = withRedux(App);
export default AppWithStoreAndRoutes;
