import axios from "axios";
import config from "../config.json";
import {
  deleteTokens,
  getAccessToken,
  getRefreshToken,
  setAccessToken,
} from "@entities/auth";
// Предположим, что эти функции определены

// Создаем экземпляр Axios
const http = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? config.baseUrl
      : config.baseUrlProd,
  headers: {
    Authorization: `Basic YXV0aG9yaXR5ZG9jdW1lbnQ6a11WXnw7MydLOSM2TFVvaFhOQjQ=`,
  },
});

// Добавляем Interceptor для запросов
http.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`; // Добавляем токен в заголовки
    }
    return config;
  },
  (error) => Promise.reject(error) // Обработка ошибок запроса
);

// Добавляем Interceptor для ответов
http.interceptors.response.use(
  (response) => response, // Возвращаем ответ, если всё прошло успешно
  async (error) => {
    const originalRequest = error.config;
    const refreshToken = getRefreshToken();

    // Проверяем, если ошибка связана с истекшим токеном
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      refreshToken
    ) {
      originalRequest._retry = true; // Флаг, чтобы избежать зацикливания запросов

      try {
        const res = await axios.post(`${config.baseUrl}/auth/token/refresh/`, {
          refresh: refreshToken,
        });

        const newAccessToken = res.data.access;
        setAccessToken(newAccessToken); // Сохраняем новый токен

        // Обновляем заголовок Authorization и повторяем оригинальный запрос
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return http(originalRequest);
      } catch (err) {
        deleteTokens(); // Удаляем токены, если refresh-токен тоже недействителен
        window.location.href = "/"; // Перенаправляем на страницу входа
      }
    }

    return Promise.reject(error); // Прокидываем ошибку дальше, если обработка невозможна
  }
);

// Экспортируем обертку над Axios
const httpService = {
  get: http.get,
  post: http.post,
  put: http.put,
  delete: http.delete,
};

export default httpService;
