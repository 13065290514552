import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useGetOutDoorEventsFormByIdQuery,
  usePatchStatusOutDoorEventMutation,
  useDeleteOutDoorEventMutation,
} from "../../../store/RTKApi/outdoorEvents";
import {
  calendarOutdoor,
  locationOutdoor,
  messageOutdoor,
  phoneOutdoor,
} from "../../../images";
import { CustomButton, Divider } from "../../ui";
import { Button, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const statusOptions = [
  { label: "Планируется", value: "PL" },
  { label: "Подтверждено", value: "CF" },
  { label: "На выезде", value: "AC" },
  { label: "Завершено", value: "FN" },
  { label: "Отменено", value: "CN" },
];

const approveDeleteStatus = ["AC", "FN"];

const EventOrganizationSuccessForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: formData, isSuccess: successData } =
    useGetOutDoorEventsFormByIdQuery(id);

  const [patchStatus, { isLoading: statusLoading }] =
    usePatchStatusOutDoorEventMutation();

  const [deleteOutdoor] = useDeleteOutDoorEventMutation();

  const [statusState, setStatusState] = useState(statusOptions[0]);

  useEffect(() => {
    if (successData && formData.status) {
      const currentStatus = statusOptions.find(
        (el) => el.value === formData.status
      );
      if (currentStatus) {
        setStatusState(currentStatus);
      }
    }
  }, [successData, formData]);

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatusState(selectedStatus);

    patchStatus({
      id: formData.id,
      body: { status: selectedStatus.value },
    })
      .unwrap()
      .then(() => {
        toast.success("Статус обновлен");
      });
  };

  const handleDelete = async () => {
    await deleteOutdoor({ id });
    navigate("../");
  };

  const isCurrentStatusApproved = approveDeleteStatus.includes(
    statusState.value
  );

  return (
    <div className="flex flex-col gap-2.5 text-sm font-rubik">
      {formData && (
        <>
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-center">
              <h2 className="text-xl">
                {formData.route ? (
                  <Link
                    className="text-text-primary-green underline"
                    to={`/ways/${formData.route}`}
                  >
                    {formData.title}
                  </Link>
                ) : (
                  formData.title
                )}
              </h2>
              <Select
                onChange={handleStatusChange}
                name="status"
                value={statusState}
                sx={{ width: 200, maxWidth: 400, borderRadius: "5px" }}
                disabled={
                  statusLoading ||
                  statusState.value === "FN" ||
                  statusState.value === "CN"
                }
              >
                {statusOptions.map((el) => (
                  <MenuItem
                    key={el.value}
                    value={el}
                    disabled={
                      isCurrentStatusApproved &&
                      !approveDeleteStatus.includes(el.value)
                    }
                  >
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex gap-16 items-center">
              <div className="flex gap-2.5">
                <span className="text-gray-400">Дата выезда:</span>
                <span>
                  {new Date(formData.date_leave_moscow).toLocaleDateString()}
                </span>
              </div>
              <div className="flex gap-2.5 items-center">
                <img src={calendarOutdoor} alt="icon" />
                <div>
                  <span className="text-gray-400">Дата возвращения:</span>
                  <span>
                    {new Date(formData.date_return_moscow).toLocaleDateString()}
                  </span>
                </div>
              </div>
              <div className="flex gap-2.5 items-center">
                <img src={locationOutdoor} alt="icon" />
                <div>
                  <span className="text-gray-400">Пункт назначения:</span>
                  <span>{formData.destination_address}</span>
                </div>
              </div>
            </div>
          </div>
          <Divider />

          <div className="flex gap-16 items-center">
            <div>
              <span className="text-gray-400">Количество:</span>
              <span>{formData.students_amount}</span>
            </div>
          </div>

          <Divider />
          <div className="flex flex-col gap-6">
            <span className="text-gray-400">Ответственные сопровождающие:</span>
            <div className="flex gap-5">
              {formData.accompany.map((el) => (
                <div key={el.full_name} className="flex gap-16 items-center">
                  <div className="flex flex-col gap-2.5">
                    <h2 className="text-base">{el.full_name}</h2>
                    <div className="flex gap-2.5">
                      <img src={phoneOutdoor} alt="icon" />
                      <span>{el.contact_number}</span>
                    </div>
                    <div className="flex gap-2.5">
                      <img src={messageOutdoor} alt="icon" />
                      <span>{el.email}</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <Divider />
            <div className="flex flex-col gap-6">
              <div className="flex items-center gap-16">
                <div className="flex gap-2.5 items-center">
                  <span className="text-gray-400">Автобус</span>
                  <span>{formData.bus_ordered ? "Да" : "Нет"}</span>
                </div>
                <div className="flex gap-2.5 items-center">
                  <span className="text-gray-400">Заказ питания</span>
                  <span>{formData.food_ordered ? "Да" : "Нет"}</span>
                </div>
              </div>
              <div className="flex mt-5 gap-8 mb-5 items-center">
                {/* {statusState.value === "FN" && (
                  <CustomButton
                    styles="bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                    onClick={() => navigate(`../addNote/${formData.id}`)}
                    type="button"
                  >
                    Написать заметку
                  </CustomButton>
                )} */}
                <CustomButton
                  styles="bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  onClick={() => navigate(`../edit/${formData.id}`)}
                  disabled={statusState.value !== "PL"}
                >
                  Редактировать
                </CustomButton>

                <CustomButton
                  onClick={() => navigate("../")}
                  styles="bg-text-primary-green text-lg px-4 hover:bg-text-secondary-green whitespace-nowrap w-full md:w-80"
                  type="button"
                >
                  Отменить
                </CustomButton>
                {!approveDeleteStatus.includes(statusState.value) && (
                  <CustomButton
                    onClick={handleDelete}
                    styles="bg-red-700 text-lg px-4 hover:bg-red-900 whitespace-nowrap w-full md:w-80"
                    type="button"
                  >
                    Удалить
                  </CustomButton>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EventOrganizationSuccessForm;
