import "./passwordBar.scss";

export const PasswordBar = ({ password, setValid }) => {
  // Функция для оценки сложности пароля
  const evaluatePassword = (password) => {
    const checks = [
      {
        test: (pwd) => pwd.length >= 8,
        message: "Пароль должен быть не менее 8 символов",
      },
      {
        test: (pwd) => /[A-Z]/.test(pwd),
        message: "Добавьте хотя бы одну заглавную букву",
      },
      {
        test: (pwd) => /[a-z]/.test(pwd),
        message: "Добавьте хотя бы одну строчную букву",
      },
      { test: (pwd) => /\d/.test(pwd), message: "Добавьте хотя бы одну цифру" },
      {
        test: (pwd) => /[@$!%*?&]/.test(pwd),
        message:
          "Добавьте хотя бы один специальный символ (@, $, !, %, *, ?, &)",
      },
    ];

    let score = 0;
    const failedChecks = [];

    for (const check of checks) {
      if (check.test(password)) {
        score += 1;
      } else {
        failedChecks.push(check.message);
        break; // Прерываем цикл, если текущая проверка не пройдена
      }
    }

    // Устанавливаем валидность, если пройдено хотя бы 4 проверки
    setValid(score >= 4);

    return { score, failedChecks };
  };

  const getStrengthDescription = (strength) => {
    const descriptions = [
      "Ненадежный",
      "Ненадежный",
      "Ненадежный",
      "Ненадежный",
      "Надежный",
      "Очень надежный",
    ];
    return descriptions[strength];
  };

  const getStrengthColor = (strength) => {
    const colors = {
      1: "red",
      2: "orange",
      3: "yellow",
      4: "lightgreen",
      5: "green",
    };
    return colors[strength] || "gray";
  };

  const { score: strength, failedChecks } = evaluatePassword(password);
  const strengthDescription = getStrengthDescription(strength);
  const strengthColor = getStrengthColor(strength);

  return (
    <>
      {password && (
        <div className="password-strength-container">
          <div className="strength-bar">
            <div
              className="strength-fill"
              style={{
                width: `${(strength / 5) * 100}%`,
                backgroundColor: strengthColor,
              }}
            ></div>
          </div>

          <p className="description">Сложность: {strengthDescription}</p>
          {failedChecks.length > 0 && (
            <ul className="failed-checks">
              {failedChecks.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </>
  );
};
