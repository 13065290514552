import React, { useState } from "react";
import { Field } from "./Field";
import styles from "./LoginForm.module.scss";

// import {
//   handleAuthError,
//   setAccessToken,
//   setRefreshToken,
//   useGetTokenMutation,
// } from "@entities/auth";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { CustomButton, InputField } from "@components/ui";
import {
  useLoginUserMutation,
  useResetPasswordMutation,
} from "store/RTKApi/auth";
import {
  handleAuthError,
  setAccessToken,
  setRefreshToken,
} from "@entities/auth";
import { authCheck } from "store/auth/auth.slice";
import { useDispatch } from "react-redux";
import { validateForm } from "./validator";
import { showToast } from "utils";
import { PasswordBar } from "./passwordBar";

export const LoginForm = ({ isReset }) => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [isValidFlag, setisValid] = useState(false);
  const [errors, setErrors] = useState({});

  const isValid = !isReset || (isValidFlag && Object.keys(errors).length === 0);

  // const [getTokenTrigger] = useGetTokenMutation({});
  const [triggerLogin] = useLoginUserMutation({});
  const [triggerReset] = useResetPasswordMutation({});

  const handleChange = (target) => {
    setFormData((prev) => ({ ...prev, [target.name]: target.value.trim() }));
    setErrors({});
  };

  const dispatch = useDispatch();

  const uidb = params.get("uidb64"),
    token = params.get("token");
  if (isReset && (!uidb || !token)) return <Navigate to={"/login"} />;
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isReset) {
      try {
        validateForm(formData);
      } catch ({ name, errors }) {
        const error = { name, message: errors[0] };

        setErrors(error);
        return;
      }
    }

    if (isReset && (!uidb || !token)) {
      showToast("error", "Неверный uibd64 или token");

      return;
    }

    isReset
      ? triggerReset({
          uidb,
          token,
          password: formData.password,
        })
          .unwrap()
          .then(() => {
            showToast("success", "Вход выполнен");
            navigate("/login");
          })
          .catch((e) => {
            toast.error(
              e.data.password
                ? e.data.password[0]
                : "Ошибка восстановления пароля",
              {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              }
            );
          })
      : triggerLogin(formData)
          .unwrap()
          .then((res) => {
            setAccessToken(res.access);
            setRefreshToken(res.refresh);
            dispatch(authCheck(res.access));
            toast.success("Вход выполнен", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            debugger;
            navigate("/account");
          })
          .catch((err) => {
            handleAuthError(err);
          });
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit}>
      <h1 className={styles.title}>
        {isReset ? "Установить пароль" : "Добро пожаловать"}
      </h1>
      {!isReset && (
        <InputField
          name={"email"}
          value={formData.email}
          onChange={handleChange}
          placeholder={"Введите почту"}
          styles={" w-full"}
        />
      )}
      <div>
        <InputField
          name={"password"}
          value={formData.password}
          onChange={handleChange}
          placeholder={"Введите пароль"}
          styles={" w-full"}
          type={"password"}
          error={errors?.message}
        />
        {isReset && (
          <PasswordBar password={formData.password} setValid={setisValid} />
        )}
      </div>
      <CustomButton
        styles={"bg-[#2fac66]"}
        type="submit"
        color="success"
        disabled={!isValid}
        label={isReset ? "Сохранить" : "Войти"}
      />

      {!isReset && (
        <span>
          Забыли пароль?{" "}
          <Link className="text-green-700" to={"/restore"}>
            Восстановить
          </Link>
        </span>
      )}
    </form>
  );
};
