import React from "react";
import { OrganizationEventsListItem } from "./organizationEventsListItem";

export const OrganizationEventsList = ({ events, sortOption }) => {
  const sorted = [...events].sort(
    (a, b) => new Date(b[sortOption.value]) - new Date(a[sortOption.value])
  );

  return (
    <div className=" mb-20">
      {sorted?.map((event) => (
        <OrganizationEventsListItem event={event} key={event.id} />
      ))}
    </div>
  );
};
