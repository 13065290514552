export function objectToFormData(obj, form, namespace) {
  const formData = form || new FormData();

  for (const property in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, property)) {
      const formKey = namespace ? `${namespace}` : property;
      const value = obj[property];

      // Проверяем, является ли значением массив
      if (Array.isArray(value)) {
        value.forEach((element) => {
          const arrayKey = `${formKey}`; // Используем array[] вместо array[index]

          // Рекурсивно обрабатываем элементы массива
          if (
            typeof element === "object" &&
            !(element instanceof File) &&
            !(element instanceof Blob)
          ) {
            objectToFormData(element, formData, arrayKey);
          } else {
            formData.append(arrayKey, element);
          }
        });
      }
      // Проверяем, если это объект, но не файл
      else if (
        typeof value === "object" &&
        !(value instanceof File) &&
        !(value instanceof Blob)
      ) {
        // Специальная обработка для поля poster
        if (property === "poster" && value.path) {
          const file = value.path;
          formData.append(formKey, file);
        } else {
          objectToFormData(value, formData, formKey);
        }
      }
      // Если это не объект или это файл, добавляем его в FormData
      else {
        formData.append(formKey, value);
      }
    }
  }

  return formData;
}
