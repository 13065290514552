import React from "react";
import {
  Box,
  Button,
  InputLabel,
  Select,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, useFieldArray } from "react-hook-form";
import httpService from "service/http.service";
import { objectToFormData } from "utils";
import { getAccessToken } from "@entities/auth";

export const FeedbackForm = ({ onClose }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    defaultValues: {
      attachments: [], // Инициализируем массив для файлов
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attachments", // Поле для работы с массивом файлов
  });

  const onSubmit = async (data) => {
    const formData = objectToFormData(data);
    try {
      await httpService.post("/feedback/create/", formData, {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {}

    reset(); // Сбрасываем форму после отправки
    onClose();
  };

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    files.forEach((file) => append({ file })); // Добавляем каждый файл в массив
    e.target.value = ""; // Сбрасываем input
  };

  return (
    <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" className="font-semibold">
        Обратная связь
      </Typography>

      {/* Message Type */}
      <FormControl fullWidth sx={{ mb: 3, mt: 3 }}>
        <InputLabel id="message-type-label">Тип сообщения</InputLabel>
        <Select
          labelId="message-type-label"
          label="Тип сообщения"
          id="message-type"
          defaultValue="default"
          {...register("message_type", { required: "Выберите тип сообщения" })}
          error={!!errors.messageType}
        >
          <MenuItem disabled value="default">
            Выбрать
          </MenuItem>
          <MenuItem value={1}>Предложение</MenuItem>
          <MenuItem value={2}>Ошибка</MenuItem>
          <MenuItem value={3}>Сообщение</MenuItem>
        </Select>
        {errors.messageType && (
          <Typography color="error" variant="body2">
            {errors.messageType.message}
          </Typography>
        )}
      </FormControl>

      {/* Subject */}
      <TextField
        label="Тема сообщения"
        variant="outlined"
        fullWidth
        sx={{ mb: 3 }}
        {...register("subject", {
          required: "Введите тему сообщения",
          maxLength: {
            value: 50,
            message: "Тема сообщения не должна превышать 50 символов",
          },
        })}
        error={!!errors.subject}
        helperText={errors.subject?.message}
      />

      {/* Message */}
      <TextField
        label="Сообщение"
        variant="outlined"
        fullWidth
        multiline
        rows={4}
        sx={{ mb: 3 }}
        {...register("body", {
          required: "Введите описание",
          minLength: {
            value: 10,
            message: "Сообщение должно содержать не менее 10 символов",
          },
        })}
        error={!!errors.message}
        helperText={errors.message?.message}
      />

      {/* File Attachment */}
      <Box sx={{ mb: 3 }}>
        <label className="cursor-pointer text-green-500 inline-flex items-center gap-2">
          📎 <span className="underline">Прикрепить файлы</span>
          <input
            type="file"
            className="hidden"
            multiple
            onChange={handleFileChange}
          />
        </label>
        {fields.length > 0 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body2" className="text-gray-500 mb-2">
              Прикрепленные файлы:
            </Typography>
            {fields.map((item, index) => (
              <Box
                key={item.id}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ mb: 1 }}
              >
                <Typography variant="body2">{item.file.name}</Typography>
                <IconButton
                  color="error"
                  onClick={() => remove(index)} // Удаляем файл по индексу
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        )}
      </Box>

      {/* Submit Button */}
      <Button
        type="submit"
        variant="contained"
        className="!bg-text-primary-green !text-white"
        sx={{ py: 1.5 }}
      >
        Отправить
      </Button>
    </form>
  );
};
