import React, { useState } from "react";
import { eyeIcon } from "images";

export const InputField = ({
  type,
  placeholder,
  styles,
  children,
  onChange,
  name,
  value,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const cls = `h-[50px] rounded-md p-[15px] border my-1 leading-[18px] font-normal text-[14px] text-text-primary font-rubik placeholder:text-text-gray outline-none`;

  const handleChange = (e) => {
    onChange({ name, value: e.target.value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div>
      <div className="relative w-full">
        <input
          className={`${cls} ${styles}`}
          type={
            type === "password"
              ? showPassword
                ? "text"
                : "password"
              : type || "text"
          }
          placeholder={placeholder || ""}
          onChange={handleChange}
          value={value || ""}
          name={name}
        />
        {type === "password" && (
          <img
            className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer"
            src={eyeIcon}
            alt="Показать пароль"
            onClick={togglePasswordVisibility}
          />
        )}
        {children}
      </div>{" "}
      {error && <span className="text-red-800">{error}</span>}
    </div>
  );
};

export default InputField;
